import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"

import Header from "./header"
import ContactModal from "./contactModal"
import Footer from "./footer"
import "./styles/layout.css"

const duration = 0.4

const contentAnimation = {
  initial: {
    y: 20,
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      ease: "easeInOut",
    },
  },
}

const Layout = ({ children, location }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <Header modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <ContactModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={contentAnimation}
          initial="initial"
          animate="animate"
          exit="initial"
        >
          {children}
        </motion.main>
        <Footer />
      </AnimatePresence>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
