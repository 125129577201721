import React from "react"

import * as styles from "./styles/footer.module.css"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span>
        This site is an ongoing archive of my work. No trackers or analytics are being used.
      </span>
      <span>
        <a href="https://twitter.com/joshuasoehn">Twitter</a>,&nbsp;
        <a href="https://instagram.com/joshuasoehn">Instagram</a>,&nbsp;	
        <a href="mailto:joshua.soehn@gmail.com">E-Mail</a>,&nbsp;
        <a href="https://read.cv/joshuasoehn">Curriculum Vitae</a>
      </span>
    </footer>
  )
}

export default Footer
