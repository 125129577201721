import React, { useState, useEffect } from "react"
import * as styles from "./styles/contactModal.module.css"
import { motion, AnimatePresence } from "framer-motion"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import * as buttonStyles from "./styles/button.module.css"

const backgroundAnimation = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
}
const modalAnimation = {
  closed: {
    y: "100vh",
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  submitted: {
    y: "-100vh",
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  open: {
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.4,
      ease: "easeInOut",
    },
  },
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactModal = ({ modalIsOpen, setModalIsOpen }) => {
  useEffect(() => {
    const targetElement = document.querySelector("#modal")
    if (modalIsOpen) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  })

  const [values, setValues] = useState({
    email: "",
    message: "",
  })

  const [exitAnimation, setExitAnimation] = useState("closed")

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    console.log(values)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...values,
      }),
    })
      .then(() => { 
        setExitAnimation("submitted")
        setModalIsOpen(false)
      })
      .then(() => setValues({ email: "", message: "" }))
      .catch(error => alert(error))
  }

  function close() {
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <AnimatePresence>
      {modalIsOpen && (
        <>
          <motion.div
            className={styles.background}
            variants={backgroundAnimation}
            initial="closed"
            animate="open"
            exit="closed"
          ></motion.div>
          <motion.div
            id="modal"
            className={styles.modal}
            variants={modalAnimation}
            initial="closed"
            animate="open"
            exit={exitAnimation}
          >
            <form
              name="contact"
              onSubmit={handleSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <div className={styles.actions}>
                <a onClick={close}>Close</a>
                <button
                  type="submit"
                  className={`${buttonStyles.button} ${buttonStyles.primaryButton}`}
                >
                  Send
                </button>
              </div>

              <h1>New Message</h1>

              <div className={styles.recipient}>
                <span>To:</span>
                <span><a href="mailto:hello@joshuasoehn.com">hello@joshuasoehn.com</a></span>
              </div>

              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>

              <div className={styles.sender}>
                <span>From:</span>
                <input
                  required
                  placeholder="Your Email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.message}>
                <textarea
                  required
                  placeholder="Type in your little message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                ></textarea>
              </div>
            </form>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

export default ContactModal
