import React from "react"
import { Link } from "gatsby"

import * as headerStyles from "./styles/header.module.css"
import * as buttonStyles from "./styles/button.module.css"

const Header = ({ modalIsOpen, setModalIsOpen }) => {
  function openContactModal() {
    setModalIsOpen(!modalIsOpen)
  }
  return (
    <header className={headerStyles.header}>
      <span className={headerStyles.name}>
        <Link to="/">Joshua Söhn</Link>
      </span>
      <span className={headerStyles.years}>Selected Work 2016 – 2022</span>
      <span>
        <button
          onClick={openContactModal}
          className={`${buttonStyles.button} ${headerStyles.button}`}
        >
          Contact
        </button>
      </span>
    </header>
  )
}

export default Header
